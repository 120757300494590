import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';

import { fetch_one, fetch_all, fetch_all_with_count } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupIcon from '@mui/icons-material/Group';
import MailIcon from '@mui/icons-material/Mail';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PhoneIcon from '@mui/icons-material/Phone';
import RepeatIcon from '@mui/icons-material/Repeat';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  Alert,
  Avatar,
  Box,
  Button as Btn,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Stack,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';

import {
  Button,
  DateAndTimePicker,
  DatePicker,
  Dt,
  Select,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from './Base';

import { Appointment } from '../../../models/Appointment';
import { AppointmentType } from '../../../models/AppointmentType';
import { Audit } from '../../../models/Audit';
import { Location } from '../../../models/Location';
import { User } from '../../../models/User';

import HelpersLanguage from '../../../actions/helpers/language';
import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {
  open: boolean;
  setOpen: (b:boolean) => void;
  appointment: Appointment;
  setAppointment: (a: Appointment) => void;
  refetch: () => void;
  checkVacation?: (day: any) => boolean;
  checkOverlap: (app_id?: number, coach_id?:number, start_time?: string, end_time?: string) => boolean;
};

export const CoachAgendaEvent: FunctionComponent<Props> = ({open, setOpen, appointment, setAppointment, checkVacation, checkOverlap, refetch}) => {
  const theme = useTheme();
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);

  const [currentTime, setCurrentTime] = useState<string>(DateTime.now().toFormat("dd/LL/y t"));
  const [currentEndTime, setCurrentEndTime] = useState<string>(DateTime.now().toFormat("dd/LL/y t"));
  const [timeField, setTimeField] = useState<string>(DateTime.now().toFormat("t"));
  const [timeEndField, setTimeEndField] = useState<string>(DateTime.now().toFormat("t"));
  const [amountField, setAmountField] = useState<string>('0.0');
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [appTypeFilter, setAppTypeFilter] = useState<number>(0);
  const [vacationWarning, setVacationWarning] = useState<boolean>(false);
  const [clientExists, setClientExists] = useState<boolean>(true);
  const [overlapWarning, setOverlapWarning] = useState<boolean>(false);
  const [tabRepeatActive, setTabRepeatActive] = useState<boolean>(false);
  const [tabRemoveActive, setTabRemoveActive] = useState<boolean>(false);
  const [currentAllowance, setCurrentAllowance] = useState<{locations: number[], types: number[]}>({locations: [], types: []});
  const [clientSearch, setClientSearch] = useState<string>('');
  let hasVideo = false;
  let hasPhysical = false;
  let count = 0;
  const [page, setPage] = useState<number>(0);
  const whitelistedFields = [
    'coach_id', 'user_id', 'location_id', 'appointment_type_id', 'practice_id', 'start_time', 'end_time', 'comment', 'comment_coach', 'comment_cancel', 'is_notify_mail', 'is_notify_sms', 'is_noshow', 'is_cancelled',
    'is_cancelled_late', 'is_cancelled_by_coach', 'email', 'comment_yellow', 'sms_sent_at', 'is_meeting', 'id'
  ];
  const [emailError, setEmailError] = useState<boolean>(false);
  const [editAll, setEditAll] = useState<boolean>(false);
  const [cancellation, setCancellation] = useState<{
    appointment_id: number;
    comment_cancel: string;
    no_show: boolean;
    delete_all: boolean;
    move_after: boolean;
  }>({
    appointment_id: 0,
    comment_cancel: '',
    no_show: false,
    delete_all: false,
    move_after: false
  });
  const [recurrence, setRecurrence] = useState<{
    appointment_id: number;
    limited_times: boolean;
    clone_times?: number;
    end_date?: string;
    repeat_weeks: number;
  }>({
    appointment_id: 0,
    limited_times: false,
    clone_times: 1,
    end_date: '',
    repeat_weeks: 1
  });

  let is_nutri_mail = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_mail'});
  let is_payments = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'payments'});


  const {
    data: appointmentTypes,
    isLoading: appointmentTypesLoading,
    isError: appointmentTypesError,
    isSuccess: appointmentTypesSuccess,
    // refetch: appointmentTypesRefetch,
  } = useQuery({
    queryKey: ["appointment_types_mollie", currentPractice?.id],
    queryFn: () =>
      fetch_all<AppointmentType>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'appointment_type',
          fields: ['id', 'name', 'colour', 'is_meeting', 'mollie', 'cost'],
          order: "sort_order ASC, name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    select: (d) => {
      hasVideo = d.filter(ff => ff.is_meeting).length > 0;
      hasPhysical = d.filter(ff => !ff.is_meeting).length > 0;
      return d;
    },
    enabled: !!currentPractice?.id
  });

  const {
    data: clients,
    isLoading: clientsLoading,
    isError: clientsError,
    isSuccess: clientsSuccess,
    // refetch: clientsRefetch,
  } = useQuery({
    queryKey: ["clients", "with_email", currentPractice?.id, clientSearch],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'user',
          fields: ['id', 'full_name', 'last_login_date', 'language_id', 'email'],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: clientSearch,
            advanced: {
              for_practice: (login?.practice_id || currentPractice?.id),
              // enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", 'with_sec', currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name', 'secretary_allowed', 'is_secretary'],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: (login?.practice_id || currentPractice?.id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });

  const {
    data: locations,
    // isLoading: locationsLoading,
    // isError: locationsError,
    isSuccess: locationsSuccess,
    // refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations_short", currentPractice?.id],
    queryFn: () =>
      fetch_all<Location>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: [
            'id', 'name'
          ],
          page: 0,
          per_page: 1000,
          order: "name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: currentPractice?.id,
              enabled: 1
            }
          }
        },
        login
      ),
    // select: (d) => {
    //   hasVideo = d.filter(ff => ff.is_meeting).length > 0;
    //   hasPhysical = d.filter(ff => !ff.is_meeting).length > 0;
    //   return d;
    // },
    enabled: !!currentPractice?.id,
  });
  const {
    data: logs,
    isLoading: logsLoading,
    isError: logsError,
    isSuccess: logsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["audits", "NutriCalendar::Appointment", appointment?.id, page],
    queryFn: () =>
      fetch_all_with_count<Audit>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/fetch_all`,
        {
          object: 'audit',
          fields: ['id', 'action', 'version', 'audited_changes', 'created_at'],
          per_page: 10,
          page: page,
          order: "id DESC",
          filter: {
            advanced: {
              audit_type: "NutriCalendar::Appointment",
              audit_id: appointment?.id
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!login && !!appointment?.id,
  });







  const mutationCustomActionCheckVacation = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{
        custom_result: boolean
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (!!data.custom_result) {
        setVacationWarning(true);
      }
    },
  });
  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
      extra?: any;
    }) => {
      return fetch_one<{
        custom_result: {
          success: boolean,
          error?: string
        }
      }>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      // if (!!refetch) refetch();
      // navigate("/");
      setOpen(false);
      setAppointment({
        start_time: '',
        end_time: ''
      });
      setCurrentTab(0);
      refetch();
    },
  });








  useEffect(() => {
    setCurrentTime(appointment.start_time);
    setCurrentEndTime(appointment.end_time);
    if (currentTab === 0) setCurrentTab(!!appointment.id ? 0 : 1);
    if (currentTab > 2) setCurrentTab(!!appointment.id ? 0 : 1);
    setOverlapWarning(checkOverlap(appointment.id, appointment.coach_id, appointment.start_time, appointment.end_time));
  }, [appointment]);
  useEffect(() => {
    setCurrentAllowance(((coaches || []).filter(fff => fff.id === login?.id)[0] || {}).secretary_allowed || {locations: [], types: []});
  }, [coaches]);

  // useEffect(() => {
  //   // console.log(currentTime);
  //   setTimeField(DateTime.fromFormat(currentTime, "dd/LL/y t").toFormat("t"));
  // }, [currentTime]);

  // useEffect(() => {
  //   // console.log(currentTime);
  //   setTimeEndField(DateTime.fromFormat(currentEndTime, "dd/LL/y t").toFormat("t"));
  // }, [currentEndTime]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     // if (e.replace(/[^0-9\:]/g, '').split(":").length == 2) {
  //       setCurrentTime(DateTime.fromFormat(currentTime, "dd/LL/y t").set({
  //         hour: parseInt(timeField.replace(/[^0-9\:]/g, '').split(":")[0] || '0', 10),
  //         minute: parseInt(timeField.replace(/[^0-9\:]/g, '').split(":")[1] || '0', 10)
  //       }).toFormat("dd/LL/y t"));
  //     // }
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   }
  // }, [timeField]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     // if (e.replace(/[^0-9\:]/g, '').split(":").length == 2) {
  //       setCurrentEndTime(DateTime.fromFormat(currentEndTime, "dd/LL/y t").set({
  //         hour: parseInt(timeEndField.replace(/[^0-9\:]/g, '').split(":")[0] || '0', 10),
  //         minute: parseInt(timeEndField.replace(/[^0-9\:]/g, '').split(":")[1] || '0', 10)
  //       }).toFormat("dd/LL/y t"));
  //     // }
  //   }, 1000);

  //   return () => {
  //     clearTimeout(timer);
  //   }
  // }, [timeEndField]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAppointment({
        ...appointment,
        payable_amount: !!amountField ? parseFloat(amountField) : undefined
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
    }
  }, [amountField]);

  useEffect(() => {
    if ((appointmentTypes || []).filter(fff => fff.id === appointment.appointment_type_id)[0] && (appointmentTypes || []).filter(fff => fff.id === appointment.appointment_type_id)[0].mollie?.enabled) {
      let apptype = (appointmentTypes || []).filter(fff => fff.id === appointment.appointment_type_id)[0]
      setAmountField(apptype?.cost?.toString() || '0.0');
    }

    if (!appointment.appointment_type_id) {
      setCurrentEndTime(DateTime.fromFormat(currentTime, "dd/LL/y t").plus({minutes: parseInt(currentPractice?.settings?.yellow_duration || '30', 10)}).toFormat("dd/LL/y t"));
    }
  }, [appointment.appointment_type_id]);

  useEffect(() => {
    if (!!appointment.email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!appointment.email || (!!appointment.email && appointment.email.trim().length > 0 && re.test(appointment.email))) {
        //all_okay
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    } else {
      setEmailError(false);
    }
  }, [appointment.email]);

  useEffect(() => {
    setCurrentTab(!!appointment.id ? 0 : 1);
    setTabRepeatActive(false);
    setTabRemoveActive(false);
  }, [appointment.id]);

  useEffect(() => {
    if (!!appointment.coach_id) {
      mutationCustomActionCheckVacation.mutate({
        formData: {
          object: 'appointment',
          class_action: 'custom_api_check_vacation_date',
          practice_id: (login?.practice_id || currentPractice?.id),
          coach_id: appointment.coach_id,
          from: DateTime.fromFormat(currentTime, "dd/LL/y t").toFormat("y-LL-dd"),
          to: DateTime.fromFormat(currentTime, "dd/LL/y t").toFormat("y-LL-dd")
        }
      });
    }
  }, [!!appointment.coach_id, currentTime]);

  return (<>
    <Base
      name="coach_agenda_event"
      hidden={!open}
      title={t(!!appointment.id ? (currentTab === 5 ? "coach.submenu.agenda.cancel_appointment" : (currentTab === 4 ? "coach.submenu.agenda.repeat_appointment" : "coach.submenu.agenda.change_appointment")) : "coach.submenu.agenda.new_appointment")}
      fullWidth
      maxWidth="lg"
      onClose={() => {
        setCurrentTab(0);
      }}
      content={<Grid container spacing={1}>

        <Grid item xs={12}>
          <Box>
            <Box sx={{
              marginBottom: 3,
              '& .Mui-selected': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)'
              }
            }}>
              <Tabs
                value={currentTab}
                onChange={(e, v) => {
                  setCurrentTab(v);
                  if (v === 0 || v === 3) {
                    setTabRepeatActive(false);
                    setTabRemoveActive(false);
                  }
                }}
                indicatorColor="primary"
                textColor="primary"
                aria-label="Filters"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {!!appointment.id && <Tab disabled={!appointment.id} value={0} label={t("coach.submenu.agenda.appointments.detail")} />}
                {!appointment.id && <Tab disabled={!!appointment.id} value={1} label={t("coach.submenu.agenda.appointments.locoat", "Basis")} />}
                {!appointment.id && !!appointment.appointment_type_id && <Tab disabled={!!appointment.id} value={2} label={t("coach.submenu.agenda.appointments.client")} />}
                {!!appointment.id && <Tab value={3} label={`${t("coach.submenu.agenda.appointments.log")} (${count})`} />}
                {!!tabRepeatActive && !!appointment.id && <Tab disabled value={4} label={t("coach.submenu.agenda.appointments.repeat")} />}
                {!!tabRemoveActive && !!appointment.id && <Tab disabled value={5} label={t("coach.submenu.agenda.appointments.cancel")} />}
              </Tabs>
              <Divider sx={{borderColor: theme.palette.primary.main}} />
            </Box>


            {currentTab === 0 && <Grid container spacing={1}>
              <Grid item xs={12} md={8} container spacing={1}>
                {!!appointment.appointment_type_id && <Grid item xs={12}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.client")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>
                    {appointment.get_event_object?.client?.label || appointment.get_event_object?.client_email}

                    {!!appointment.get_event_object?.client?.value && <Tooltip title={t("coach.general.actions.view", "Bekijken")}>
                      <IconButton sx={{
                        padding: 0,
                        marginLeft: 2
                      }} aria-label="view" onClick={(e) => {
                        navigate(`/user/${appointment.get_event_object?.client?.value}/detail`);
                      }}>
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>}

                    {!!appointment.get_event_object?.client?.value && <Tooltip title={t("coach.general.actions.appointments", "Afspraken")}>
                      <IconButton sx={{
                        padding: 0,
                        marginLeft: 2
                      }} aria-label="view" onClick={(e) => {
                        navigate(`/user/${appointment.get_event_object?.client?.value}/appointments`);
                      }}>
                        <CalendarTodayIcon />
                      </IconButton>
                    </Tooltip>}

                    {!!appointment.get_event_object?.client?.value && !!is_nutri_mail && <Tooltip title={t("coach.general.actions.email", "E-mail")}>
                      <IconButton sx={{
                        padding: 0,
                        marginLeft: 2
                      }} aria-label="view" onClick={(e) => {
                        navigate(`/inbox`, {state: {user_id: {id: appointment.get_event_object?.client?.value}}});
                      }}>
                        <MailIcon />
                      </IconButton>
                    </Tooltip>}

                    {!!appointment.get_event_object?.client_email && !is_nutri_mail && <Tooltip title={t("coach.general.actions.email", "E-mail")}>
                      <IconButton sx={{
                        padding: 0,
                        marginLeft: 2
                      }} aria-label="view" href={`mailto:${appointment.get_event_object?.client_email}`}>
                        <MailIcon />
                      </IconButton>
                    </Tooltip>}

                    {!!appointment.get_event_object?.client_phone && <Tooltip title={t("coach.general.actions.call", "Bellen")}>
                      <IconButton sx={{
                        padding: 0,
                        marginLeft: 2
                      }} aria-label="view" href={`tel:${appointment.get_event_object?.client_phone}`}>
                        <PhoneIcon />
                      </IconButton>
                    </Tooltip>}

                    {!!appointment.get_event_object?.fm_link && <Tooltip title={t("coach.general.actions.open_in_fm", "Openen in FileMaker")}>
                      <IconButton sx={{
                        padding: 0,
                        marginLeft: 2
                      }} aria-label="view" onClick={(e) => {window.open(appointment.get_event_object?.fm_link, "_BLANK");}}>
                        <Avatar style={{width: 22, height: 22}} src="https://cdn.kangacoders.com/direct/nutrilink_eu/images/vinkje_blauw_groen.png" />
                      </IconButton>
                    </Tooltip>}

                    {!appointment.get_event_object?.client?.value && !!appointment.get_event_object?.client_email && <Tooltip title={t("coach.general.actions.create_from_email", "Aanmaken")}>
                      <IconButton sx={{
                        padding: 0,
                        marginLeft: 2
                      }} aria-label="view" onClick={(e) => {
                        navigate('/user/new', {state: {from_email: appointment.get_event_object?.client_email}});
                      }}>
                        <PersonAddIcon />
                      </IconButton>
                    </Tooltip>}

                  </Typography>
                </Grid>}

                <Grid item xs={12} md={6}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.start_time")}</Typography>
                  <Dt d={appointment.get_event_object?.time} f="DDD t" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.coach")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{appointment.get_event_object?.coach?.label}</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.end_time")}</Typography>
                  <Dt d={appointment.get_event_object?.end_time} f="DDD t" />
                </Grid>
                {!!appointment.get_event_object?.location?.label && <Grid item xs={12} md={6}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.location")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{appointment.get_event_object?.location?.label}</Typography>
                </Grid>}

                {!!appointment.get_event_object?.location?.label && <Grid item xs={12} md={6}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.type")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{!!appointment.appointment_type_id ? appointment.get_event_object?.type?.label : t("coach.submenu.agenda.appointments.yellow_appointment")}</Typography>
                </Grid>}
                {!!appointment.appointment_type_id && <Grid item xs={12} md={6}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.reminder_email")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{!!appointment.get_event_object?.reminders?.email ? t("coach.submenu.agenda.appointment_detail.option_yes") : t("coach.submenu.agenda.appointment_detail.option_no")}</Typography>
                </Grid>}

                {!!appointment.appointment_type_id && <Grid item xs={12} md={6}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.reminder_sms")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{!!appointment.get_event_object?.reminders?.sms ? t("coach.submenu.agenda.appointment_detail.option_yes") : t("coach.submenu.agenda.appointment_detail.option_no")}</Typography>
                </Grid>}
                {!!appointment.appointment_type_id && appointment.get_event_object?.reminders?.sms_sent_at !== '-' && <Grid item xs={12} md={6}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.reminder_sms_sent_at")}</Typography>
                  <Dt d={appointment.get_event_object?.reminders?.sms_sent_at} f="DDD t" />
                </Grid>}

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                {!!appointment.get_event_object?.client_note && <Grid item xs={12}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.client_note")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{appointment.get_event_object?.client_note}</Typography>
                </Grid>}
                {!!appointment.get_event_object?.note && <Grid item xs={12}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.note")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{appointment.get_event_object?.note}</Typography>
                </Grid>}
                {!!appointment.get_event_object?.cancel_note && <Grid item xs={12}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.cancel_note")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{appointment.get_event_object?.cancel_note}</Typography>
                </Grid>}
                {!!appointment.get_event_object?.yellow_note && <Grid item xs={12}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.yellow_note")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{appointment.get_event_object?.yellow_note}</Typography>
                </Grid>}
                {!!is_payments && !!appointment.get_event_object?.mollie?.order && <Grid item xs={12}>
                  <Typography sx={{
                    fontWeight: 'bold'
                  }}>{t("coach.submenu.agenda.appointment_detail.paid")}</Typography>
                  <Typography sx={{paddingLeft: 1}}>{appointment.get_event_object?.mollie?.paid ? t("coach.submenu.agenda.appointment_detail.paid_yes") : t("coach.submenu.agenda.appointment_detail.paid_no")} ({appointment.get_event_object?.mollie?.order_amount} EUR)</Typography>
                </Grid>}

              </Grid>
              <Grid item xs={12} md={4} container spacing={1} sx={{alignContent: 'baseline'}}>
                <Grid item xs={12}>
                  <Typography sx={{
                    textDecoration: 'underline'
                  }}>{t("coach.submenu.agenda.appointment_detail.actions")}</Typography>
                </Grid>
                {!!appointment.get_event_object?.can_meeting && <Grid item xs={12}>
                  <Button
                    id="videocall"
                    fullwidth
                    contained
                    onClick={(e) => {
                      e.preventDefault();
                      // navigate("/meeting", {state: {client: this.state.active_client, appointment_id: appointment.id}});
                      navigate("/meeting", {state: {id: appointment.id, appointment_id: appointment.id}});
                    }}
                    sx={{marginTop: 0}}
                    startIcon={<VideoCameraFrontIcon />}
                    label={t("coach.general.actions.video")}
                  />
                </Grid>}
                <Grid item xs={12}>
                  <Button
                    id="edit"
                    fullwidth
                    contained
                    onClick={(e) => {
                      setCurrentTab(1);
                    }}
                    sx={{marginTop: 0}}
                    startIcon={<ModeEditIcon />}
                    label={t("coach.general.actions.edit")}
                  />
                </Grid>
                {!appointment.recurrence_id && <Grid item xs={12}>
                  <Button
                    id="repeat"
                    fullwidth
                    contained
                    onClick={(e) => {
                      // this.setState({active_appointment: appointment, show_repeat_dialog: true});
                      setCurrentTab(4);
                      setTabRepeatActive(true);
                      setRecurrence({
                        appointment_id: (appointment.id || 0),
                        limited_times: true,
                        clone_times: 1,
                        end_date: DateTime.now().plus({weeks: 4}).toFormat("dd/LL/y"),
                        repeat_weeks: 1
                      });
                    }}
                    sx={{marginTop: 0}}
                    startIcon={<RepeatIcon />}
                    label={t("coach.general.actions.repeat")}
                  />
                </Grid>}
                {!appointment.get_event_object?.is_noshow && <Grid item xs={12}>
                  <Button
                    id="destroy"
                    fullwidth
                    contained
                    secondary
                    onClick={(e) => {
                      // this.setState({active_appointment: this.state.dialog_fields, show_cancel_dialog: true});
                      setCurrentTab(5);
                      setTabRemoveActive(true);
                      setCancellation({
                        appointment_id: (appointment.id || 0),
                        comment_cancel: '',
                        no_show: false,
                        delete_all: false,
                        move_after: false
                      });
                    }}
                    sx={{marginTop: 0}}
                    startIcon={<DeleteForeverIcon />}
                    label={t("coach.general.actions.destroy")}
                  />
                </Grid>}
              </Grid>
            </Grid>}
            {currentTab === 1 && <Grid container spacing={1}>

              <Grid item xs={12}>
                <Switcher
                  id="yellow_appointment"
                  label={t("coach.submenu.agenda.appointments.yellow_appointment", "Gele afspraak")}
                  backend
                  fullwidth
                  value={!appointment.appointment_type_id}
                  onChange={(b) => {
                    setAppointment({
                      ...appointment,
                      ...(!b ? {
                        appointment_type_id: 1,
                        coach_id: undefined,
                      } : {
                        appointment_type_id: undefined,
                        location_id: undefined,
                        // coach_id: filters.coach.length === 1 ? filters.coach[0] : login?.id,
                        user_id: undefined,
                        end_time: DateTime.fromFormat(currentTime, "dd/LL/y t").plus({minutes: parseInt(currentPractice?.settings?.yellow_duration || '30', 10)}).toFormat("dd/LL/y t")
                      })
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography sx={{
                  fontSize: '0.7rem'
                }}>{t("coach.models.appointments.time")} *</Typography>
                <DateAndTimePicker
                  disableFuture={false}
                  disablePast
                  id="current_time"
                  value={currentTime}
                  setValue={(e) => {
                    setCurrentTime(e);
                  }}
                />
                {!!vacationWarning && <Typography sx={{
                  fontSize: '0.7rem',
                  color: theme.palette.secondary.main
                }}>{t("coach.models.appointments.error_vacation")}</Typography>}
              </Grid>
              {!!appointment.appointment_type_id && <Grid item xs={12} md={6}>&nbsp;</Grid>}
              {!appointment.appointment_type_id && <Grid item xs={12} md={6}>
                <Typography sx={{
                  fontSize: '0.7rem'
                }}>{t("coach.models.appointments.end_time")} *</Typography>
                <DateAndTimePicker
                  disableFuture={false}
                  disablePast
                  id="current_time"
                  value={currentEndTime}
                  setValue={(e) => {
                    setCurrentEndTime(e);
                  }}
                />
              </Grid>}
              {!!appointment.appointment_type_id && <Grid item xs={12} md={6}>
                <Select
                  fullWidth
                  caption={t("coach.models.appointments.location", "Locatie")}
                  starred
                  displayEmpty
                  id="location"
                  value={appointment.location_id || ''}
                  setValue={(v) => {
                    setAppointment({
                      ...appointment,
                      location_id: !!v ? parseInt(v.toString(), 10) : undefined
                    });
                  }}
                >
                  <MenuItem value="">------</MenuItem>
                  {(locations || []).filter(fff => currentAllowance.locations.indexOf(fff.id || 0) > -1).map(location => <MenuItem value={location.id}>{location.name}</MenuItem>)}
                </Select>
              </Grid>}
              <Grid item xs={12} md={!!appointment.appointment_type_id ? 6 : 12}>
                <Select
                  fullWidth
                  caption={t("coach.models.appointments.coach", "Coach")}
                  starred
                  displayEmpty
                  id="coach"
                  value={appointment.coach_id || ''}
                  setValue={(v) => {
                    setAppointment({
                      ...appointment,
                      coach_id: !!v ? parseInt(v.toString(), 10) : undefined
                    });
                  }}
                >
                  <MenuItem value="">------</MenuItem>
                  {(coaches || []).filter(fff => !fff.is_secretary).map(coach => <MenuItem value={coach.id}>{coach.full_name}</MenuItem>)}
                </Select>
              </Grid>
              {!!appointment.appointment_type_id && <Grid item xs={8}>
                <Select
                  fullWidth
                  caption={t("coach.models.appointments.appointment_type", "Afspraaktype")}
                  starred
                  displayEmpty
                  id="coach"
                  value={appointment.appointment_type_id || ''}
                  setValue={(v) => {
                    setAppointment({
                      ...appointment,
                      appointment_type_id: !!v ? parseInt(v.toString(), 10) : undefined,
                      payable: (appointmentTypes || []).filter(fff => fff.id === parseInt(v.toString(), 10))[0] && (appointmentTypes || []).filter(fff => fff.id === parseInt(v.toString(), 10))[0].mollie?.enabled
                    });
                  }}
                >
                  <MenuItem value="1">------</MenuItem>
                  {(appointmentTypes || []).filter(fff => currentAllowance.types.indexOf(fff.id || 0) > -1).filter(ff => ((
                    appTypeFilter === 0 || ( // everything
                    appTypeFilter === 1 && hasVideo && ff.is_meeting // only video
                    ) || (
                      appTypeFilter === 2 && hasPhysical && !ff.is_meeting // only phys
                    ) || (
                      appTypeFilter === 1 && !hasVideo && !ff.is_meeting // only phys if no video present
                    )
                  ))).map(appointmentType => <MenuItem value={appointmentType.id}>{appointmentType.name}</MenuItem>)}
                </Select>
              </Grid>}
              {!!appointment.appointment_type_id && <Grid item xs={4}>
                <Box sx={{
                  paddingTop: 3,
                  textAlign: 'center'
                }}>
                  <Tooltip title={t("client.appointment.type_filter_option.all")}>
                    <AllInclusiveIcon sx={{
                      color: (appTypeFilter === 0 ? theme.palette.primary.main : 'lightgrey'),
                      padding: 0.5,
                      borderRadius: 25,
                      border: (appTypeFilter === 0 ? '1px solid transparent' : 'none'),
                      borderColor: theme.palette.primary.main,
                      fontSize: '2rem',
                      cursor: 'pointer',
                      margin: 0.5,
                      "&:hover": {
                        border: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                      }
                    }} onClick={() => {setAppTypeFilter(0);}} />
                  </Tooltip>
                  {!!hasVideo && <Tooltip title={t("client.appointment.type_filter_option.video")}>
                    <VideoCallIcon sx={{
                      color: (appTypeFilter === 1 ? theme.palette.primary.main : 'lightgrey'),
                      padding: 0.5,
                      borderRadius: 25,
                      border: (appTypeFilter === 1 ? '1px solid transparent' : 'none'),
                      borderColor: theme.palette.primary.main,
                      fontSize: '2rem',
                      cursor: 'pointer',
                      margin: 0.5,
                      "&:hover": {
                        border: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                      }
                    }} onClick={() => {setAppTypeFilter(1);}} />
                  </Tooltip>}
                  {!!hasPhysical && <Tooltip title={t("client.appointment.type_filter_option.physical")}>
                    <GroupIcon sx={{
                      color: (appTypeFilter === 2 ? theme.palette.primary.main : 'lightgrey'),
                      padding: 0.5,
                      borderRadius: 25,
                      border: (appTypeFilter === 2 ? '1px solid transparent' : 'none'),
                      borderColor: theme.palette.primary.main,
                      fontSize: '2rem',
                      cursor: 'pointer',
                      margin: 0.5,
                      "&:hover": {
                        border: '1px solid transparent',
                        borderColor: theme.palette.primary.main,
                      }
                    }} onClick={() => {setAppTypeFilter(2);}} />
                  </Tooltip>}
                </Box>
              </Grid>}
              {!!appointment.appointment_type_id && appointment.appointment_type_id > 1 && is_payments && <>
                {!!appointment.get_event_object?.mollie?.payable && <Grid item xs={12}>
                  <Typography variant="caption">{t("coach.submenu.agenda.appointments.payable_edit_message", "Let op, deze afspraak is {paid_status}, ter waarde van {amount}. Er kan geen nieuwe betaling voor deze afspraak gemaakt worden.").replace("{paid_status}", (!!appointment.get_event_object?.mollie?.paid ? t("coach.terms.paid", "betaald") : t("coach.terms.unpaid", "onbetaald"))).replace("{amount}", appointment.get_event_object?.mollie?.order_amount)}</Typography>
                </Grid>}
                <Grid item xs={4}>
                  <Switcher
                    id="mollie_payable"
                    disabled={typeof(appointment.get_event_object?.mollie?.payable) !== 'undefined'}
                    label={t("coach.submenu.agenda.appointments.payable", "Betaling actief")}
                    backend
                    fullwidth
                    value={!!appointment.payable || !!appointment.get_event_object?.mollie?.payable}
                    onChange={(b) => {
                      setAppointment({
                        ...appointment,
                        payable: !!b
                      });
                    }}
                    sx={{
                      paddingTop: 3.5
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextInput
                    id="mollie_amount"
                    caption={t("coach.models.appointments.payable_amount", "Bedrag")}
                    value={amountField}
                    onChange={(e) => {
                      setAmountField(e);
                    }}
                  />
                </Grid>
              </>}
              {!appointment.appointment_type_id && <Grid item xs={12}>
                <TextInput
                  id="note"
                  caption={t("coach.models.appointments.title", "Title")}
                  starred
                  value={appointment.comment_coach || ''}
                  onChange={(e) => {
                    setAppointment({
                      ...appointment,
                      comment_coach: e
                    });
                  }}
                  sx={{marginBottom: 1}}
                />
              </Grid>}
              {!appointment.appointment_type_id && <Grid item xs={12}>
                <TextInput
                  id="note"
                  caption={t("coach.models.appointments.yellow_note", "Gele opmerking")}
                  value={appointment.comment_yellow || ''}
                  multiline
                  onChange={(e) => {
                    setAppointment({
                      ...appointment,
                      comment_yellow: e
                    });
                  }}
                  sx={{marginBottom: 1}}
                />
              </Grid>}
              {!!appointment.recurrence_id && <Grid item xs={12}>
                <Switcher
                  id="recurring_edit_all"
                  label={t("coach.submenu.agenda.recurring_edit_all", "Alle volgende instanties ook wijzigen?")}
                  backend
                  fullwidth
                  value={!!appointment.edit_all}
                  onChange={(b) => {
                    setAppointment({
                      ...appointment,
                      edit_all: !!b
                    });
                  }}
                />
              </Grid>}

            </Grid>}

            {currentTab === 2 && <Grid container spacing={1}>

              <Grid item xs={12}>
                <Switcher
                  id="client_exists"
                  label={t("coach.agenda.appointment.client_exists_switcher")}
                  backend
                  fullwidth
                  value={!!clientExists}
                  onChange={(b) => {
                    setClientExists(!!b);
                    setAppointment({
                      ...appointment,
                      user_id: undefined,
                      language_id: undefined,
                      email: undefined
                    });
                    setClientSearch('');
                  }}
                />
              </Grid>
              {clientExists && <>
                <Grid item xs={12} md={6}>
                  <Typography sx={{
                    textDecoration: 'underline'
                  }}>{t("coach.agenda.appointment.client_exists")}</Typography>
                  <TextInput
                    id="client_search"
                    caption={t("coach.models.appointments.client_search", "Zoek client")}
                    value={clientSearch}
                    onChange={(e) => {
                      setClientSearch(e);
                    }}
                  />
                  <Typography sx={{fontSize: '0.7rem', fontStyle: 'italic', marginBottom: 1}}>{(clients || []).length} {t("general.results_found")}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{
                    // textDecoration: 'underline'
                  }}>&nbsp;</Typography>
                  <Select
                    fullWidth
                    caption={t("coach.models.appointments.client", "Client")}
                    starred
                    displayEmpty
                    id="user_id"
                    value={appointment.user_id || ''}
                    setValue={(v) => {
                      setAppointment({
                        ...appointment,
                        user_id: !!v ? parseInt(v.toString(), 10) : undefined
                      });
                    }}
                  >
                    <MenuItem value="">------</MenuItem>
                    {(clients || []).map(client => <MenuItem value={client.id}>{client.full_name} <span style={{fontSize: '0.8rem'}}>({client.email})</span></MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography sx={{fontSize: '0.7rem', marginTop: 1}}>{t("coach.models.clients.language_id", "Taalkeuze")}</Typography>
                  <Typography>{!!appointment.user_id ? HelpersLanguage.mapping_to_iso(((clients || []).filter(fff => fff.id === appointment.user_id)[0] || {})?.language_id || 1) : '-'}</Typography>
                </Grid>
                {!!currentPractice?.settings?.is_sms_enabled && <Grid item xs={12} md={6}>
                  <Switcher
                    id="is_notify_sms"
                    label={t("coach.submenu.agenda.is_notify_sms", "SMS herinnering?")}
                    backend
                    fullwidth
                    value={!!appointment.is_notify_sms}
                    onChange={(b) => {
                      setAppointment({
                        ...appointment,
                        is_notify_sms: !!b
                      });
                    }}
                  />
                </Grid>}
              </>}
              {!clientExists && <>
                <Grid item xs={12}>
                  <Typography sx={{
                    textDecoration: 'underline'
                  }}>{t("coach.agenda.appointment.client_exists_not")}</Typography>
                  <TextInput
                    id="email"
                    caption={t("coach.models.appointments.client_input", "Client email")}
                    starred
                    type="email"
                    value={appointment.email || ''}
                    onChange={(e) => {
                      setAppointment({
                        ...appointment,
                        email: e
                      });
                    }}
                    sx={{marginBottom: 1}}
                  />
                  {emailError && <Typography sx={{fontSize: '0.7rem', marginTop: -1, color: theme.palette.secondary.main}}>{t("shared.forgot_password.error.invalid_email")}</Typography>}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    fullWidth
                    caption={t("coach.models.clients.language_id", "Taalkeuze")}
                    displayEmpty
                    id="language_id"
                    value={appointment.language_id || ''}
                    setValue={(v) => {
                      setAppointment({
                        ...appointment,
                        language_id: !!v ? parseInt(v.toString(), 10) : undefined
                      });
                    }}
                  >
                    <MenuItem value="">------</MenuItem>
                    {HelpersLanguage.available_locales().map(lang_iso => <MenuItem value={HelpersLanguage.mapping_to_id(lang_iso)}>{lang_iso}</MenuItem>)}
                  </Select>
                </Grid>
              </>}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {!!overlapWarning && <Grid item sm={12}>
                <Typography sx={{color: theme.palette.secondary.main}}>{t("coach.submenu.agenda.appointments.overlap_warning")}</Typography>
              </Grid>}
              {!!overlapWarning && <Grid item xs={12}>
                <Divider />
              </Grid>}
              <Grid item xs={12}>
                <TextInput
                  id="note"
                  caption={t("coach.models.appointments.note", "Opmerking")}
                  value={appointment.comment_coach || ''}
                  multiline
                  onChange={(e) => {
                    setAppointment({
                      ...appointment,
                      comment_coach: e
                    });
                  }}
                  sx={{marginBottom: 1}}
                />
              </Grid>

            </Grid>}

            {currentTab === 3 && <Grid container spacing={1}>
              {(logs || []).map(log => <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                  <Typography sx={{fontWeight: 'bold'}}><Dt d={log.created_at} f="DDD t" /> - {log.action} - v{log.version}</Typography>
                </Grid>
                {Object.keys(log.audited_changes).filter(fff => whitelistedFields.indexOf(fff) > -1).length < 1 && <Grid item xs={12}>
                  <Typography sx={{fontStyle: 'italic', color: 'lightgrey', paddingLeft: 1}}>Hidden data</Typography>
                </Grid>}
                {whitelistedFields.map(field => !!log.audited_changes[field] ? <Grid item xs={6}>
                  {field !== 'start_time' && field !== 'end_time' && <Typography sx={{paddingLeft: 1}}>{field}: {Array.isArray(log.audited_changes[field]) ? log.audited_changes[field].join(" => ") : log.audited_changes[field]}</Typography>}
                  {/* @ts-ignore */}
                  {(field == 'start_time' || field == 'end_time') && <Typography sx={{paddingLeft: 1}}>{field}: {Array.isArray(log.audited_changes[field]) ? log.audited_changes[field].map(mm => DateTime.fromISO(mm).toFormat("DDD t")).join(" => ") : <Dt d={log.audited_changes[field]} f="DDD t" />}</Typography>}
                </Grid> : <></>)}
              </Grid>)}
              <Grid item xs={12}>
                <Stack spacing={2} alignItems="center">
                  <Pagination count={Math.ceil(count/11)} page={page + 1} onChange={(e, v) => {setPage(v - 1);}} boundaryCount={2} siblingCount={2} />
                </Stack>
              </Grid>
            </Grid>}

            {currentTab === 4 && <Grid container spacing={1}>
              {!!appointment.get_event_object?.mollie?.payable && <Grid item xs={12}>
                <Alert severity="error">{t("coach.submenu.agenda.recurrences.no_paylink_note")}</Alert>
              </Grid>}
              <Grid item xs={12}>
                <Alert severity="info">{t("coach.submenu.agenda.recurrences.current_is_part_of_recurrence_notice")}</Alert>
              </Grid>

              <Grid item xs={12}>
                <Switcher
                  id="limited_times"
                  label={t("coach.submenu.agenda.recurrences.limited_times", "Gelimiteerd aantal herhalingen")}
                  backend
                  fullwidth
                  value={!!recurrence.limited_times}
                  onChange={(b) => {
                    setRecurrence({
                      ...recurrence,
                      limited_times: !!b
                    });
                  }}
                />
              </Grid>
              {!!recurrence.limited_times && <Grid item xs={12}>
                <TextInput
                  id="comment_cancel"
                  caption={t("coach.models.recurrences.clone_times", "Aantal keren dat de afspraak moet voorkomen (deze meegerekend)")}
                  value={recurrence.clone_times?.toString() || '1'}
                  onChange={(e) => {
                    setRecurrence({
                      ...recurrence,
                      clone_times: !!e ? parseInt(e, 10) : 1,
                      end_date: undefined
                    });
                  }}
                />
              </Grid>}
              {!recurrence.limited_times && <Grid item xs={12} md={6}>
                <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.recurrences.end_time")}</Typography>
                <DatePicker
                  disableFuture={false}
                  disablePast
                  openTo="day"
                  label=""
                  format={"dd/LL/y"}
                  // caption=''
                  views={["year", "month", "day"]}
                  setValue={(e) => {
                    setRecurrence({
                      ...recurrence,
                      end_date: e,
                      clone_times: undefined
                    });
                  }}
                  value={recurrence.end_date || DateTime.now().toFormat("dd/LL/y")}
                  id="rec_date"
                  sx={{
                    box: {
                      width: 'auto',
                      display: 'inline-block',
                      marginRight: 0.5
                    },
                    field_box: {
                      "svg": {
                        display: 'none'
                      }
                    },
                    field: {
                      width: 'auto',
                      marginTop: 0,
                      "input": {
                        textAlign: 'center'
                      }
                    }
                  }}
                />
              </Grid>}
              {!recurrence.limited_times && <Grid item xs={12} md={6}>
                <TextInput
                  id="repeat_weeks"
                  caption={t("coach.models.recurrences.repeat_weeks", "Aantal weken per herhaling. 1 wilt zeggen elke week.")}
                  value={recurrence.repeat_weeks.toString()}
                  onChange={(e) => {
                    setRecurrence({
                      ...recurrence,
                      repeat_weeks: !!e ? parseInt(e, 10) : 1
                    });
                  }}
                />
              </Grid>}

              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!mutationCustomAction.isPending && <Button
                  id="save"
                  label={t("coach.general.actions.save")}
                  onClick={() => {
                    mutationCustomAction.mutate({
                      formData: {
                        object: 'appointment',
                        class_action: 'custom_api_save_recurrence',
                        ob: recurrence,
                        practice_id: currentPractice?.id
                      }
                    });
                  }}
                  contained
                />}
                {!!mutationCustomAction.isPending && <CircularProgress />}
              </Grid>
            </Grid>}

            {currentTab === 5 && <Grid container spacing={1}>
              {!!appointment.appointment_type_id && DateTime.now() >= DateTime.fromFormat(appointment.start_time, "dd/LL/y t").minus({hours: currentPractice?.settings?.cancel_late_time || 24}) && <Grid item xs={12}>
                <Alert severity="error">{t("general.confirm.delete_late_appointment_coach")}</Alert>
              </Grid>}
              {(!appointment.appointment_type_id || DateTime.now() < DateTime.fromFormat(appointment.start_time, "dd/LL/y t").minus({hours: currentPractice?.settings?.cancel_late_time || 24})) && <Grid item xs={12}>
                <Alert severity="warning">{t("general.confirm.delete")}</Alert>
              </Grid>}

              {!!appointment.appointment_type_id && <Grid item xs={12}>
                <TextInput
                  id="comment_cancel"
                  caption={t("coach.models.appointments.cancel_note", "Opmerking bij de annulering. Let op, de client kan deze ook zien!")}
                  multiline
                  value={cancellation.comment_cancel}
                  onChange={(e) => {
                    setCancellation({
                      ...cancellation,
                      comment_cancel: e
                    });
                  }}
                />
              </Grid>}

              {!!appointment.appointment_type_id && <Grid item xs={12}>
                <Switcher
                  id="is_noshow"
                  label={t("coach.submenu.agenda.appointments.is_noshow", "Deze klant is niet komen opdagen")}
                  backend
                  fullwidth
                  value={!!cancellation.no_show}
                  onChange={(b) => {
                    setCancellation({
                      ...cancellation,
                      no_show: !!b
                    });
                  }}
                />
              </Grid>}

              {!!appointment.recurrence_id && <Grid item xs={12}>
                <Divider sx={{marginBottom: 1}} />
              </Grid>}
              {!!appointment.recurrence_id && <Grid item xs={12} md={6}>
                <Switcher
                  id="delete_all"
                  label={t("coach.submenu.agenda.recurring_delete_all")}
                  backend
                  fullwidth
                  value={!!cancellation.delete_all}
                  onChange={(b) => {
                    setCancellation({
                      ...cancellation,
                      delete_all: !!b,
                      move_after: false
                    });
                  }}
                />
              </Grid>}
              {!!appointment.recurrence_id && <Grid item xs={12} md={6}>
                <Switcher
                  id="move_after"
                  label={t("coach.submenu.agenda.recurring_move_after_delete")}
                  disabled={!!cancellation.delete_all}
                  backend
                  fullwidth
                  value={!!cancellation.move_after}
                  onChange={(b) => {
                    setCancellation({
                      ...cancellation,
                      move_after: !!b
                    });
                  }}
                />
              </Grid>}

              <Grid item xs={12}>
                <Divider sx={{marginTop: 1}} />
                {!mutationCustomAction.isPending && <Button
                  id="destroy"
                  label={t("coach.general.actions.destroy")}
                  secondary
                  startIcon={<DeleteForeverIcon />}
                  destroyBtn
                  onClick={() => {
                    mutationCustomAction.mutate({
                      formData: {
                        object: 'appointment',
                        class_action: 'custom_api_cancel_appointment',
                        ob: cancellation,
                        practice_id: currentPractice?.id
                      }
                    });
                  }}
                  contained
                />}
                {!!mutationCustomAction.isPending && <CircularProgress />}
              </Grid>
            </Grid>}

            {currentTab > 0 && currentTab < 3 && <Grid item xs={12}>
              <Divider sx={{marginTop: 1}} />
              {!mutationCustomAction.isPending && <Button
                id="save"
                disabled={(!!appointment.appointment_type_id && (appointment.appointment_type_id === 1 || !appointment.location_id || !appointment.coach_id || !appointment.appointment_type_id || (!appointment.user_id && !appointment.email))) || (!appointment.appointment_type_id && false)}
                label={t("coach.general.actions.save")}
                onClick={() => {
                  mutationCustomAction.mutate({
                    formData: {
                      object: 'appointment',
                      class_action: 'custom_api_save_appointment',
                      ob: {
                        ...appointment,
                        start_time: currentTime,
                        end_time: currentEndTime
                      },
                      practice_id: currentPractice?.id
                    }
                  });
                }}
                contained
              />}
              {!!mutationCustomAction.isPending && <CircularProgress />}

              {!!appointment.appointment_type_id && currentTab === 2 && <Button
                id="to_client"
                label={t("coach.agenda.appointment.to_basic")}
                onClick={() => {
                  setCurrentTab(1);
                }}
                text
                sx={{
                  marginLeft: 1
                }}
              />}
              {!!appointment.appointment_type_id && currentTab === 1 && <Button
                id="to_client"
                label={t("coach.agenda.appointment.to_client")}
                onClick={() => {
                  setCurrentTab(2);
                }}
                text
                sx={{
                  marginLeft: 1
                }}
              />}
            </Grid>}
          </Box>
        </Grid>

      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {
          setOpen(false);
          setCurrentTab(0);
        }}>{t("coach.general.actions.close")}</Btn>
        {currentTab > 0 && currentTab < 3 && !mutationCustomAction.isPending && <Btn
          id="save"
          disabled={(!!appointment.appointment_type_id && (appointment.appointment_type_id === 1 || !appointment.location_id || !appointment.coach_id || !appointment.appointment_type_id || (!appointment.user_id && !appointment.email))) || (!appointment.appointment_type_id && false)}
          onClick={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'appointment',
                class_action: 'custom_api_save_appointment',
                ob: {
                  ...appointment,
                  start_time: currentTime,
                  end_time: currentEndTime
                },
                practice_id: currentPractice?.id
              }
            });
          }}
        >{t("coach.general.actions.save")}</Btn>}
      </DialogActions>}
      open={open}
      setOpen={setOpen}
    />
  </>);
}